import { sanityConfig } from "@libry-content/common";
import { createClient, createPreviewSubscriptionHook } from "next-sanity";
import createImageUrlBuilder from "@sanity/image-url";
import { SanityImageObject } from "@sanity/image-url/lib/types/types";
import EventSource from "@sanity/eventsource";

let token: string | undefined;

// For other browsers than Chrome we need to explicitly set the auth token,
// similarly to https://github.com/sanity-io/sanity/pull/3155
if (typeof window !== "undefined" && !!localStorage) {
  const localStorageToken = localStorage.getItem(`__studio_auth_token_${sanityConfig.projectId}`);
  try {
    token = localStorageToken ? JSON.parse(localStorageToken)?.token : undefined;
  } catch (err) {
    console.error("Could not parse Sanity auth token from localStorage:", err);
  }
}

export const imageUrlBuilder = (source?: SanityImageObject) => {
  // Sånn som vi har satt opp bilder i sanity nå kan bilde-objektet eksistere selv om det ikke har asset. Det får createImageUrlBuilder til å krasje
  if (!source?.asset) return undefined;
  try {
    return createImageUrlBuilder(sanityConfig).image(source);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

export const usePreviewSubscription = createPreviewSubscriptionHook({
  ...sanityConfig,
  token,
  EventSource,
});

export const sanityClient = createClient({
  ...sanityConfig,
  token,
  useCdn: process.env.NODE_ENV === "production",
});

import { OpeningHoursTimeSpan } from "@libry-content/types";
import { DateHelper } from "./DateHelper";
import { OpeningHoursHelper } from "./openingHoursHelper";

interface TimespanWithType extends Pick<OpeningHoursTimeSpan, "opens" | "closes"> {
  type: "selfService" | "normal";
}

const opensComparator = (a: TimespanWithType, b: TimespanWithType): number => {
  const aOpens = a.opens ?? "00:00";
  const bOpens = b.opens ?? "00:00";
  if (aOpens === bOpens) return 0;
  return aOpens > bOpens ? 1 : -1;
};

const beforeOrSameAsNow = (time?: string): boolean => DateHelper.fromTime(time ?? "24:00").isSameOrBeforeNow();
const afterNow = (time?: string): boolean => DateHelper.fromTime(time ?? "00:00").isAfterNow();

function getAllTimespans(helper: OpeningHoursHelper) {
  const { normalHours, selfService } = helper.todaysOpeningHours;
  const { hasSelfService } = helper;

  const normalHourSpans: TimespanWithType[] =
    (!normalHours?.closed && normalHours?.spans?.map((span): TimespanWithType => ({ type: "normal", ...span }))) || [];
  const selfServiceSpans: TimespanWithType[] =
    (hasSelfService &&
      selfService?.enabled &&
      selfService?.spans?.map((span): TimespanWithType => ({ type: "selfService", ...span }))) ||
    [];

  const allSpans: TimespanWithType[] = [...normalHourSpans, ...selfServiceSpans];
  return allSpans.sort(opensComparator);
}

// returns a list/stack of timespans, where all timespans are within the current time. The last item is the timespan which opened last
export function getCurrentTimespans(helper: OpeningHoursHelper): TimespanWithType[] {
  const alltimespans = getAllTimespans(helper);
  return alltimespans.filter((span) => beforeOrSameAsNow(span.opens) && afterNow(span.closes));
}

export function getCurrentTimespan(helper: OpeningHoursHelper): TimespanWithType | undefined {
  return getCurrentTimespans(helper).slice(-1)[0];
}

export function getNextTimespan(helper: OpeningHoursHelper): TimespanWithType | undefined {
  const alltimespans = getAllTimespans(helper);
  const opensNext: TimespanWithType | undefined = alltimespans.filter((span) => afterNow(span.opens))[0];
  const currentTimespan = getCurrentTimespan(helper);

  if (!currentTimespan) return opensNext;

  // A difference less then 1 minute we assume that they overlap
  if (DateHelper.timeStringDifferenceInMinutes(opensNext?.opens, currentTimespan?.closes) <= 1) {
    return opensNext;
  }

  // if there is no timespan opening imediately after the current one closes we pop timespans from the currentTimespans-stack to see which timespan we "fall into" next.
  return getCurrentTimespans(helper).find((candidateTimespan) => {
    const differenceMinutes = DateHelper.timeStringDifferenceInMinutes(
      candidateTimespan.closes,
      currentTimespan?.closes
    );
    const closesAfterCurrentTimespan = differenceMinutes > 0;
    return closesAfterCurrentTimespan;
  });
}

export interface TimespanTransition {
  currentTimespan?: TimespanWithType;
  nextTimespan?: TimespanWithType;
  nextState: TimespanWithType["type"] | "closed";
  currentState: TimespanWithType["type"] | "closed";
  happensAt?: string;
}

function happensAt(nextTimespan?: TimespanWithType, currentTimespan?: TimespanWithType): string | undefined {
  if (!currentTimespan) return nextTimespan?.opens;
  if (!nextTimespan) return currentTimespan.closes;

  const nextTimespanHasAllreadyOpened = DateHelper.fromTime(nextTimespan.opens).isBefore(
    DateHelper.fromTime(currentTimespan.opens)
  );
  if (nextTimespanHasAllreadyOpened) return currentTimespan.closes;

  return nextTimespan.opens;
}

export function getNextTimespanTransition(helper: OpeningHoursHelper): TimespanTransition {
  const currentTimespan = getCurrentTimespan(helper);
  const nextTimespan = getNextTimespan(helper);

  return {
    currentTimespan,
    nextTimespan,
    currentState: currentTimespan?.type ?? "closed",
    nextState: nextTimespan?.type ?? "closed",
    happensAt: happensAt(nextTimespan, currentTimespan),
  };
}

import {
  Library,
  OpeningHours,
  OpeningHoursDay,
  OpeningHoursTimeSpan,
  SanityKeyed,
  SelfServiceOpeningHours,
  SelfServiceOpeningHoursDay,
  SpecialOpeningHours,
} from "@libry-content/types";
import {
  SIMPLE_ISO_DATE_FORMAT,
  WEEKDAY_LABELS,
  WEEKDAYS,
  Period,
  Weekday,
  getPeriodAsDates,
  getPeriodEnd,
  getNorwegianWeekday,
  getWeekdayIndex,
  isValidPeriod,
  positiveModulo,
} from "@libry-content/common";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import differenceInWeeks from "date-fns/differenceInWeeks";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import orderBy from "lodash/orderBy";

import { DateHelper } from "./DateHelper";
import { getNextTimespanTransition } from "./getTimespan";
import type { DayOfWeek, OpeningHoursSpecification } from "schema-dts";

export const SCHEMA_ORG_WEEKDAYS: Record<Weekday, DayOfWeek> = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

interface MakeOpeningHoursSpecificationsProps {
  closed?: boolean;
  spans?: OpeningHoursTimeSpan[];
  dayOfWeek?: DayOfWeek;
  validFrom?: string;
  validThrough?: string;
}

interface NormalHoursForDate extends Pick<OpeningHoursDay, "spans" | "closed"> {
  isSpecial?: boolean;
  note?: string;
}

export interface OpeningHoursForDate {
  normalHours?: NormalHoursForDate;
  selfService?: SelfServiceOpeningHoursDay;
}

// Use this to indicate that list has been vetted as valid period, to avoid filtering again
export type SpecialHoursList = SanityKeyed<SpecialOpeningHours & Period>[];

export class OpeningHoursHelper {
  private openingHours?: OpeningHours;
  private selfService?: SelfServiceOpeningHours;

  constructor(library?: Pick<Library, "openingHours" | "selfServiceOpeningHours">) {
    this.openingHours = library?.openingHours;
    this.selfService = library?.selfServiceOpeningHours;
  }

  static openingHoursToString(openingHoursDay?: OpeningHoursDay) {
    return openingHoursDay?.closed
      ? "stengt"
      : openingHoursDay?.spans?.map((span) => `${span.opens}-${span.closes}`).join(" & ");
  }

  static specialHoursIncludesDate(specialHours: SpecialOpeningHours & Period, date: Date): boolean {
    return (
      new DateHelper(specialHours.from).isSameDateOrBefore(date) &&
      new DateHelper(getPeriodEnd(specialHours)).isSameDateOrAfter(date)
    );
  }

  static getFirstOccurenceOfWeekday(startDate: Date, weekday: Weekday): Date {
    const startDateDayIndex = getWeekdayIndex(getNorwegianWeekday(startDate));
    const relativeDayIndex = positiveModulo(getWeekdayIndex(weekday) - startDateDayIndex, 7);
    return addDays(startDate, relativeDayIndex);
  }

  public get sortedWeekOpeningHours() {
    const specialHoursNextSevenDays = this.getSpecialHoursForNextDays(6);
    const today = DateHelper.now();

    return eachDayOfInterval({ start: today, end: addDays(today, 6) }).map((date) => {
      const weekday = getNorwegianWeekday(date);
      const selfService = this.selfService?.[weekday];
      const specialHoursPeriod = specialHoursNextSevenDays?.find((specialHours) =>
        OpeningHoursHelper.specialHoursIncludesDate(specialHours, date)
      );
      const label = isToday(date) ? "I dag" : isTomorrow(date) ? " I morgen" : WEEKDAY_LABELS[weekday];

      return {
        normalHours: this.openingHours?.[weekday],
        selfService: selfService?.enabled ? selfService : undefined,
        isToday: getNorwegianWeekday(DateHelper.now()) === weekday,
        specialHours: specialHoursPeriod?.[weekday],
        weekday,
        label,
        date,
      };
    });
  }

  public get hasSelfService() {
    return this.sortedWeekOpeningHours.some((day) => day.selfService?.enabled);
  }

  private specialOpeningHoursForDate(date: Date): SpecialOpeningHours | undefined {
    return this.openingHours?.specialOpeningHours
      ?.filter(isValidPeriod)
      ?.find((specialHours) => OpeningHoursHelper.specialHoursIncludesDate(specialHours, date));
  }

  private normalHoursForDate(date: Date): NormalHoursForDate | undefined {
    const specialHours = this.specialOpeningHoursForDate(date);
    const weekday = getNorwegianWeekday(date);

    if (specialHours) {
      return {
        ...specialHours[weekday],
        note: specialHours.note,
        isSpecial: true,
      };
    }

    return this.openingHours?.[weekday];
  }

  private selfServiceOpeningHoursForDate(date: Date): SelfServiceOpeningHoursDay | undefined {
    return this.selfService?.[getNorwegianWeekday(date)];
  }

  public openingHoursForDate(date: Date): OpeningHoursForDate {
    return {
      normalHours: this.normalHoursForDate(date),
      selfService: this.selfServiceOpeningHoursForDate(date),
    };
  }

  public get todaysOpeningHours() {
    return this.openingHoursForDate(DateHelper.now());
  }

  public get tomorrowsOpeningHours() {
    return this.openingHoursForDate(DateHelper.daysFromNow(1));
  }

  public get nextTransition() {
    return getNextTimespanTransition(this);
  }

  public get specialHoursStatus() {
    const specialHoursNextSevenDays = this.getSpecialHoursForNextDays(6);
    const sixDaysFromNow = DateHelper.daysFromNow(6);
    if (!specialHoursNextSevenDays?.length) return undefined;

    return specialHoursNextSevenDays
      ?.flatMap((specialHours) =>
        getPeriodAsDates(specialHours)
          .filter((date) => new DateHelper(date).isSameDateOrBefore(sixDaysFromNow))
          .map((date) => {
            const weekday = getNorwegianWeekday(date);
            const hoursAsString = OpeningHoursHelper.openingHoursToString(specialHours[weekday]);
            const dayLabel = DateHelper.getLabel(date, "eeee");
            return `${hoursAsString} ${dayLabel}`;
          })
      )
      .join(", ");
  }

  public upcomingSpecialHours(startDate: Date = new Date()): SpecialHoursList | undefined {
    const startDateAsTimeString = format(startDate, SIMPLE_ISO_DATE_FORMAT);

    return (
      this.openingHours?.specialOpeningHours
        // Filter away invalid special hours
        ?.filter(isValidPeriod)
        // Filter away special hours entirely in the past
        .filter((specialHours) => new DateHelper(getPeriodEnd(specialHours)).isSameDateOrAfter(startDate))
        // Trim dates so that earlier ones are not included
        .map(({ from, ...rest }) => ({
          from: new DateHelper(from!).isBefore(startDate) ? startDateAsTimeString : from,
          ...rest,
        }))
        // Sort by beginning of interval
        .sort((a, b) => (a.from! > b.from! ? 1 : -1))
    );
  }

  public getSpecialHoursForNextDays(nDays: number): SpecialHoursList | undefined {
    const nDaysFromNow = DateHelper.daysFromNow(nDays);

    return (
      this.upcomingSpecialHours()
        // Since we know the list of special hours is upcoming, it suffices to
        // check that they are the same day as, or before, the end of the interval
        ?.filter(({ from }) => new DateHelper(from!).isSameDateOrBefore(nDaysFromNow))
        // Trim dates so that those pase nDays are not included
        .map(({ to, ...rest }) => ({
          to: new DateHelper(to!).isSameDateOrBefore(nDaysFromNow) ? to : format(nDaysFromNow, SIMPLE_ISO_DATE_FORMAT),
          ...rest,
        }))
    );
  }

  private makeOpeningHoursSpecifications({
    closed,
    spans,
    dayOfWeek,
    validFrom,
    validThrough,
  }: MakeOpeningHoursSpecificationsProps) {
    const baseProps = <OpeningHoursSpecification>{
      "@type": "OpeningHoursSpecification",
      validFrom,
      validThrough,
      ...(dayOfWeek ? { dayOfWeek } : {}),
    };
    return closed
      ? [baseProps]
      : (spans || []).map(({ opens, closes }) => ({
          ...baseProps,
          opens,
          closes,
        }));
  }

  public get schemaOrgOpeningHours() {
    return WEEKDAYS.flatMap((weekday) =>
      this.makeOpeningHoursSpecifications({
        dayOfWeek: SCHEMA_ORG_WEEKDAYS[weekday],
        ...this.openingHours?.[weekday],
      })
    );
  }

  public get schemaOrgSpecialOpeningHours(): OpeningHoursSpecification[] {
    return (this.upcomingSpecialHours() || []).flatMap((spec) => {
      const startDate = new Date(spec.from);
      const endDate = new Date(spec.to || spec.from);

      // Single day
      if (!spec.isInterval) {
        return this.makeOpeningHoursSpecifications({
          validFrom: format(startDate, "yyyy-MM-dd"),
          validThrough: format(startDate, "yyyy-MM-dd"),
          spans: spec[getNorwegianWeekday(startDate)]?.spans,
          closed: spec[getNorwegianWeekday(startDate)]?.closed,
        });
      }

      // < 1 week
      if (differenceInWeeks(endDate, startDate) < 1) {
        return eachDayOfInterval({ start: startDate, end: endDate }).flatMap((date) => {
          return this.makeOpeningHoursSpecifications({
            validFrom: format(date, "yyyy-MM-dd"),
            validThrough: format(date, "yyyy-MM-dd"),
            spans: spec[getNorwegianWeekday(date)]?.spans,
            closed: spec[getNorwegianWeekday(date)]?.closed,
          });
        });
      }

      // >= 1 week
      return WEEKDAYS.flatMap((weekday) => {
        return this.makeOpeningHoursSpecifications({
          validFrom: format(startDate, "yyyy-MM-dd"),
          validThrough: format(endDate, "yyyy-MM-dd"),
          dayOfWeek: SCHEMA_ORG_WEEKDAYS[weekday],
          spans: spec[weekday]?.spans,
          closed: spec[weekday]?.closed,
        });
      });
    });
  }

  private static sortByFromDate(specialHours: SpecialHoursList) {
    return orderBy(specialHours, ({ from }) => from);
  }

  // Have to take into account periods that span several months
  public specialOpeningHoursGroupedByMonth(startDate: Date, nMonthsAhead: number = 6) {
    const specialOpeningHours = this.upcomingSpecialHours(startDate) ?? [];
    const monthCutoffDate = lastDayOfMonth(addMonths(new Date(), nMonthsAhead));

    return (
      OpeningHoursHelper.sortByFromDate(specialOpeningHours)
        // Filter out special opening hours completely outside the cutoff
        .filter(({ from }) => !isAfter(new Date(from), monthCutoffDate))
        .reduce((groups, specialHours) => {
          const periodStart = new Date(specialHours.from);
          const periodEnd = new Date(getPeriodEnd(specialHours));

          const updatedGroups = eachMonthOfInterval({ start: periodStart, end: periodEnd }).reduce(
            (acc, firstOfMonth) => {
              // Filter out outside months for special opening hours partly outside the cutoff
              if (firstOfMonth > monthCutoffDate) return acc;
              const monthNumber = getMonth(firstOfMonth);

              // Truncate at start/end of month if period goes outside
              const lastOfMonth = lastDayOfMonth(firstOfMonth);
              const truncatedFromDate = isBefore(periodStart, firstOfMonth) ? firstOfMonth : periodStart;
              const truncatedToDate = isAfter(periodEnd, lastOfMonth) ? lastOfMonth : periodEnd;

              const truncatedFromString = format(truncatedFromDate, SIMPLE_ISO_DATE_FORMAT);
              const truncatedToString = format(truncatedToDate, SIMPLE_ISO_DATE_FORMAT);
              const truncatedSpecialHours = { ...specialHours, from: truncatedFromString, to: truncatedToString };

              return { ...acc, [monthNumber]: [...(groups[monthNumber] ?? []), truncatedSpecialHours] };
            },
            {}
          );

          return { ...groups, ...updatedGroups };
        }, {} as Record<number, SpecialHoursList>)
    );
  }
}

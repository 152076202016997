import { ts } from "@libry-content/localization";
import { DecorativeImage, ImageWithMetadata, Library } from "@libry-content/types";
import { sortAlphabetically } from "../../utils/sortAlphabetically";
import { useSiteContext } from "../layout/SiteContext";
import { ResolvedSite } from "../site/sanityQuery";
import type { Library as SchemaOrgLibrary } from "schema-dts";
import { getSiteUrl } from "@libry-content/common";
import { OpeningHoursHelper } from "../openingHours/openingHoursHelper/openingHoursHelper";
import { imageUrlBuilder } from "../../utils/sanity/client";

export const getLibraryUrl = (library: Pick<Library, "slug">) => `/bibliotek/${library?.slug}`;

export const useSortedLibraries = (libraries?: Library[]) => {
  const { site } = useSiteContext();
  const hovedBibliotek = libraries?.find((library) => site?.mainLibrary?._ref === library._id);
  const resten = libraries?.filter((library) => library !== hovedBibliotek);
  const restenSortert = sortAlphabetically(resten, (lib) => ts(lib.name));

  if (!hovedBibliotek) return restenSortert;
  return [hovedBibliotek, ...restenSortert];
};

export const getSchemaOrgImageUrl = (image: ImageWithMetadata | DecorativeImage) =>
  imageUrlBuilder(image) // https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1638963510039100
    ?.ignoreImageParams()
    .size(100, 100)
    .fit("fillmax")
    .bg("0000")
    .format("png")
    .url() ?? "";

export const getSchemaOrgLibrary = (
  library: Partial<Library>,
  site: ResolvedSite
): Exclude<SchemaOrgLibrary, string> => {
  const openingHoursHelper = new OpeningHoursHelper(library);
  const visitingAddress = library.contactInfo?.visitingAddress;
  return {
    "@type": "Library",
    name: ts(library.name),
    url: `${getSiteUrl(site)}${getLibraryUrl(library)}`,
    openingHoursSpecification: openingHoursHelper.schemaOrgOpeningHours,
    specialOpeningHoursSpecification: openingHoursHelper.schemaOrgSpecialOpeningHours,
    telephone: library.contactInfo?.phone,
    email: library.contactInfo?.email,
    address: visitingAddress && {
      "@type": "PostalAddress",
      streetAddress: visitingAddress?.streetAddress,
      postalCode: visitingAddress?.postalCode,
      addressLocality: visitingAddress?.city,
    },
    ...(library.image?.asset ? { image: getSchemaOrgImageUrl(library.image) } : {}),
  };
};

import eachDayOfInterval from "date-fns/eachDayOfInterval";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isEqual from "date-fns/isEqual";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import { OpeningHoursDay, OpeningHoursTimeSpan } from "@libry-content/types";
import zip from "lodash/zip";

export type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export const WEEKDAY_LABELS: Record<Weekday, string> = {
  monday: "Mandag",
  tuesday: "Tirsdag",
  wednesday: "Onsdag",
  thursday: "Torsdag",
  friday: "Fredag",
  saturday: "Lørdag",
  sunday: "Søndag",
};

export const WEEKDAYS = Object.keys(WEEKDAY_LABELS) as Weekday[];

export const isWeekday = (potentialWeekday: string): potentialWeekday is Weekday =>
  Object.keys(WEEKDAY_LABELS).includes(potentialWeekday);

const NORWEGIAN_TIMEZONE = "Europe/Oslo";

export const norwegianDateToUTC = (date: Date | string) => zonedTimeToUtc(date, NORWEGIAN_TIMEZONE);

export const UTCDateToNorwegian = (date: Date | string) => utcToZonedTime(date, NORWEGIAN_TIMEZONE);

export const getNorwegianWeekday = (date: Date | string): Weekday => {
  const weekday = format(UTCDateToNorwegian(date), "iiii").toLowerCase();
  if (!isWeekday(weekday)) throw new Error("Could not get Norwegian weekday");
  return weekday;
};

export const getWeekdayIndex = (weekday: Weekday): number => WEEKDAYS.indexOf(weekday);

export const createConstantWeekObject = <T extends unknown>(dayValue: T): Record<Weekday, T> =>
  WEEKDAYS.reduce(
    (acc, weekday) => ({
      ...acc,
      [weekday]: dayValue,
    }),
    {} as Record<Weekday, T>
  );

export type Period = { from: string; isInterval?: boolean; to?: string };

export const getPeriodEnd = <T extends Period>({ from, isInterval, to }: T) => (isInterval ? to : from) as string;

export const isValidPeriod = <T extends Partial<Period>>(potentialPeriod: T): potentialPeriod is T & Period => {
  const { from, isInterval, to } = potentialPeriod;
  if (!from || (isInterval && !to)) return false;

  const fromDate = new Date(from);
  const toDate = new Date(getPeriodEnd(potentialPeriod as Period));

  return isEqual(fromDate, toDate) || isAfter(toDate, fromDate);
};

export const getPeriodAsDates = ({ from, isInterval, to }: Period): Date[] => {
  const fromDate = new Date(from!);
  return isInterval ? eachDayOfInterval({ start: fromDate, end: new Date(to!) }) : [fromDate];
};

export const SIMPLE_ISO_DATE_FORMAT = "yyyy-MM-dd";

export const timeIsAfter = (time1: string, time2: string): boolean => {
  const [hours1, minutes1] = time1.split(":");
  const [hours2, minutes2] = time2.split(":");

  if (!hours1 || !minutes1 || !hours2 || !minutes2) return false;
  if (parseInt(hours1) > parseInt(hours2)) return true;
  return hours1 === hours2 && parseInt(minutes1) > parseInt(minutes2);
};

const spansEqual = (timespan1?: OpeningHoursTimeSpan, timespan2?: OpeningHoursTimeSpan) =>
  timespan1?.opens === timespan2?.opens && timespan1?.closes === timespan2?.closes;

const compareSpansByOpens = (span1: OpeningHoursTimeSpan, span2: OpeningHoursTimeSpan) =>
  !span1?.opens || !span2?.opens || timeIsAfter(span1.opens, span2.opens) ? 1 : -1;

const orderSpans = (spans?: OpeningHoursTimeSpan[]): OpeningHoursTimeSpan[] =>
  spans?.slice().sort(compareSpansByOpens) ?? [];

export const openingHoursDaysEqual = (
  { closed: closed1, spans: spans1 }: OpeningHoursDay,
  { closed: closed2, spans: spans2 }: OpeningHoursDay
) =>
  !!closed1 === !!closed2 &&
  zip(orderSpans(spans1), orderSpans(spans2)).every(([span1, span2]) => spansEqual(span1, span2));

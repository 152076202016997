import { ts } from "@libry-content/localization";
import { DecorativeImage, Library } from "@libry-content/types";
import type { Library as SchemaOrgLibrary } from "schema-dts";
import { getSiteUrl } from "@libry-content/common";

import { ResolvedSite } from "./sanityQuery";
import { getSchemaOrgImageUrl, getSchemaOrgLibrary } from "../library/utils";

export const getSchemaOrgSiteImage = (site?: ResolvedSite): DecorativeImage | undefined =>
  (site?.logo?.asset && site.logo) || site?.coatOfArms;

export const getSiteDescription = (site?: ResolvedSite): string =>
  ts(site?.teaser) || `Velkommen til ${ts(site?.name)}`;

/**
 * Describe site as a schema.org Library that extends the main library and includes data about the different branches under `department`.
 * https://developers.google.com/search/docs/advanced/structured-data/local-business#multiple-departments
 */
export const getSchemaOrgSite = (
  libraries: Library[],
  site: ResolvedSite
): Exclude<SchemaOrgLibrary, string> | undefined => {
  const siteImage = getSchemaOrgSiteImage(site);
  const mainLibrary = libraries.find(({ _id }) => _id === site.mainLibrary?._ref) ?? libraries[0];

  if (!mainLibrary) return undefined;

  return {
    ...getSchemaOrgLibrary(mainLibrary, site),
    name: ts(site.name),
    url: getSiteUrl(site),
    description: getSiteDescription(site),
    department: libraries.map((library) => getSchemaOrgLibrary(library, site)),
    ...(siteImage?.asset ? { image: getSchemaOrgImageUrl(siteImage) } : {}),
  };
};

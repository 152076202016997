import { isProduction } from "./isProduction";
import { Site } from "@libry-content/types";
import { LanguageCode, LocalizedField, DEFAULT_LANGUAGE_CODE } from "@libry-content/localization";
import { getPlatformUrl, platform } from "./platform";

export interface SpecialSite {
  _id: string;
  subdomain?: string;
  customDomain?: string;
  name: LocalizedField<string>;
  languages: LanguageCode[];
}

export type SiteOrSpecialSite = Pick<Site, keyof SpecialSite> | SpecialSite;

export const ADMIN_SITE: SpecialSite = {
  _id: "sites.admin",
  name: {
    nb: "Adminsida",
  },
  subdomain: "admin",
  languages: [DEFAULT_LANGUAGE_CODE],
};

export const SPLASH_SITE: SpecialSite = {
  _id: "sites.splash",
  name: {
    nb: "Splashsida",
  },
  customDomain: isProduction ? "www.librycontent.no" : platform.host,
  languages: [DEFAULT_LANGUAGE_CODE],
};

const specialSiteIds = [ADMIN_SITE._id, SPLASH_SITE._id];

export const isNormalSite = (site: SiteOrSpecialSite | undefined): site is Site =>
  site?._id !== undefined && !specialSiteIds.includes(site._id);

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (site: SiteOrSpecialSite): string =>
  site?.customDomain ? `https://${site.customDomain}` : getPlatformUrl(site.subdomain);

export const splashSiteUrl = `${platform.protocol}://${SPLASH_SITE.customDomain}`;
